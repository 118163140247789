.App {
	font-family: "Source Code Pro", monospace !important;
}

.job-board-title {
	padding-top: 25vh;
	text-align: center;
	font-size: 2rem;
}

.joboffer {
	max-width: 20vh;
}

#acuspire-job-board p {
	color: black;
}

#acuspire-jobs-widget {
	font-family: "Source Code Pro", monospace !important;
}

.acuspire-btn-apply {
	font-family: "Source Code Pro", monospace !important;
}

.sponsored {
	font-size: 2vh;
	line-height: 0;
	margin-top: 3vh;
}

.login {
	border: 3px solid black;
	background-color: rgb(233, 233, 233);
	border-radius: 0.4vh;
	margin: auto 2vh auto auto;
}

.login-wrapper {
	display: flex;
	flex-direction: row;
	font-size: 2vh;
	justify-content: flex-end;
	margin-right: 1vh;
	line-height: 1vh;
}

.login-wrapper button {
	font-family: "Source Code Pro", monospace !important;
	height: 3vh;
	min-width: 3vh;
	font-size: 1.5vh;
}

.login-wrapper a {
	margin: auto;
}

.button-container {
	display: flex;
	flex-direction: row;
	margin-left: 1vh;
}

@media only screen and (max-width: 820px) {
	.job-board-title {
		padding-top: 10vh;
	}

	.job-board-title h3 {
		margin-bottom: 0;
	}

	.job-board-title p {
		text-align: right;
		margin-right: 2vh;
	}

	.joboffer {
		max-width: 10vh;
		float: right;
		padding-right: 3vh;
	}

	.sponsored {
		display: none;
		visibility: hidden;
	}

	.login-wrapper {
		justify-content: center;
	}
	.login {
		justify-content: center;
		border: 0ch;
		margin: auto;
	}
}
