.Header {
  padding: 0px 10px;
  font-family: "Source Code Pro", monospace;
  text-align: center;
  box-shadow: 5px 5px 5px 5px #888888;
  position: fixed;
  z-index: 1000;
  width: 100%;
  background-color: white;
}

.logo {
  max-height: 15vh;
  padding: 3vh;
}

.title {
  padding: 2vh;
  text-align: center;
  font-size: 2.5vh;
  color: black;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15%;
}

a {
  text-decoration: none;
}

.links {
  flex-direction: row;
  padding: 2vh;
}

.link {
  max-height: 5vh;
  padding-right: 2vh;
}

.links a {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media only screen and (max-width: 820px) {
  .title {
    font-size: 1vh;
  }
  .title p {
    display: none;
    visibility: hidden;
  }

  .header-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .logo {
    max-height: 7vh;
  }

  .links {
    margin-right: 3vh;
  }

  .link {
    min-height: 2vh;
    max-height: 3vh;
  }

  .sidebar {
    font-family: "Source Code Pro", monospace;
    padding: 2vh;
  }

  .desktop_label {
    display: none;
    visibility: hidden;
  }
}
